import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import {Context} from "..";
import useWindowDimensions from "../core/hooks/useWindowDimensions";

const GlobalFader: FC = () => {
  const { store } = useContext(Context)
  const { width } = useWindowDimensions()
  return (
    <>
      <div 
        className="fader" 
        style={{
          display: store.isFaderVisible ? "flex" : "none", 
          opacity: store.isFaderShown ? "1" : "0",
          backgroundColor: "#000"
        }}>
        {/* <div><img style={{opacity: store.isFaderLogoVisible ? "1" : "0"}} src="/assets/images/logo_plus_release.svg" /></div> */}
        <div><img style={{
          opacity: store.isFaderLogoVisible ? "1" : "0", 
          // width: width > 768 ? "100%" : "105%", 
          maxHeight: "100%"
        }} src="/assets/images/stories/shetka.jpg" /></div>
      </div>
    </>
  )
}

export default observer(GlobalFader)