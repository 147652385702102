import moment from "moment";
import 'moment/locale/ru'
import { useMemo } from "react"

export const getDateFormat = (date: string, format: string = "DD MMM YYYY, HH:mm") => {
  moment.lang("ru")
  moment.locale("ru")
  return moment(date).format(format)
}

export const ALPHABET = "abcdefghijklmnopqrst";

export const getDateTSFormat = (ts: number, format: string = "DD MMM YYYY, HH:mm") => {
  moment.lang("ru")
  moment.locale("ru")
  return moment(ts).format(format)
}

export const splitImage = (img: string) => {
  let arr = img.split("/")
  return `https://api.topgun.ru/core/image/${arr[arr.length - 1]}`
}

export const getStorySub = (img: string) => {
  if (img.includes("Fin_2")) {
    return img.replace(".jpg", "_30.jpg")
  }
  return img
}

export function distanceLatLon(lat1: number, lon1: number, lat2: number, lon2: number, unit: string) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  } else {
    var radlat1 = Math.PI * lat1/180;
    var radlat2 = Math.PI * lat2/180;
    var theta = lon1-lon2;
    var radtheta = Math.PI * theta/180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
  }
}

export function dropNum(num: number) {
  let str = `${num}`
  let splitIndex = (str.length + 2) % 3 + 1;
  str = str.substr(0, splitIndex) + str.substr(splitIndex).replace(/\d\d\d/g, ' $&')
  return str
}

export function prevent(ev: any) {
  ev.preventDefault()
  ev.stopPropagation()
}

export function sortById(a: any, b: any) {
  if (a.id > b.id) {
    return 1
  } else if (a.id < b.id) {
    return -1
  } else {
    return 0
  }
}

export function isElementInViewport(el?: any | null): boolean {
  if (el == undefined || el == null) {
    return false;
  }
  
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while(el!.offsetParent) {
    el = el!.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }
  
  return (
    top < (window.pageYOffset + window.innerHeight) &&
    left < (window.pageXOffset + window.innerWidth)
  );
}

export function Utf8ArrayToStr(array: any) {
  var out, i, len, c;
  var char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while(i < len) {
  c = array[i++];
  switch(c >> 4)
  { 
    case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
      // 0xxxxxxx
      out += String.fromCharCode(c);
      break;
    case 12: case 13:
      // 110x xxxx   10xx xxxx
      char2 = array[i++];
      out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
      break;
    case 14:
      // 1110 xxxx  10xx xxxx  10xx xxxx
      char2 = array[i++];
      char3 = array[i++];
      out += String.fromCharCode(((c & 0x0F) << 12) |
                     ((char2 & 0x3F) << 6) |
                     ((char3 & 0x3F) << 0));
      break;
  }
  }

  return out;
}


export const fixNumber = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

export const buildParams = (map: any) => {
  let query = "";
  for (let key in map) {
    if (query == "") {
      query = `?${key}=${map[key]}`;
    } else {
      query += `&${key}=${map[key]}`;
    }
  }
  return query;
}

export function urlify(text: string) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  })
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return typeof obj === "undefined" || obj === null;
}

export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function declOfNum(n: number, text_forms: string[]) {  
  n = Math.abs(n) % 100; 
  var n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2]; }
  if (n1 > 1 && n1 < 5) { return text_forms[1]; }
  if (n1 == 1) { return text_forms[0]; }
  return text_forms[2];
}

export const getCountryCode = (country: string) => {
  if (country.slice(0, 2).toLowerCase() == "ru") {
    return "ru-RU"
  } else if (country.slice(0, 2).toLowerCase() == "en") {
    return "en-EN"
  } else if (country.slice(0, 2).toLowerCase() == "fr") {
    return "fr-FR"
  }
  return "ru-RU"
}

export function translitName(word: string) {
	let answer: string = '';
	const converter: any = {
		'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
		'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
		'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
		'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
		'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
		'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
		'э': 'e',    'ю': 'yu',   'я': 'ya',
 
		'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
		'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
		'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
		'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
		'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
		'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
		'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya'
	};
 
	for (let i = 0; i < word.length; ++i ) {
		if (converter[word[i]] == undefined){
			answer += word[i];
		} else {
			answer += converter[word[i]];
		}
	}
 
	return answer;
}

export function translit(word: string) {
	let answer: string = '';
	const converter: any = {
		'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
		'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
		'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
		'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
		'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
		'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
		'э': 'e',    'ю': 'yu',   'я': 'ya',
 
		'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
		'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
		'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
		'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
		'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
		'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
		'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya',  '  ': '',  ' ': '-', '.': '', ',': ''
	};
 
	for (let i = 0; i < word.length; ++i ) {
		if (converter[word[i]] == undefined){
			answer += word[i];
		} else {
			answer += converter[word[i]];
		}
	}
 
	return answer;
}

export function getOS() {
  const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
}

export const getStatusNameByIndex = (ix: number) => {
  switch (ix) {
    case 0:
      return "start"
    case 1:
      return "bronze"
    case 2:
      return "silver"
    case 3:
      return "gold"
    case 4:
      return "platinum"
    case 5:
      return "topblack"
    case 6:
      return "individual"
    case 7:
      return "signature"
    default:
      return "start"
  }
}

export const getStatusName = (status: string) => {
  switch (status) {
    case "start":
      return "Start"
    case "bronze":
      return "Bronze"
    case "silver":
      return "Silver"
    case "gold":
      return "Gold"
    case "platinum":
      return "Platinum"
    case "topblack":
      return "TOP BLACK"
    case "individual":
      return "Individual"
    case "signature":
      return "Signature"
    default:
      return "Start"
  }
}

export const getStatusIndex = (status: string) => {
  switch (status) {
    case "start":
      return 0   
    case "bronze":
      return 1
    case "silver":
      return 2
    case "gold":
      return 3
    case "platinum":
      return 4
    case "topblack":
      return 5
    case "individual":
      return 6
    case "signature":
      return 7
    default:
      return 0
  }
}
