import { Collapse, CollapseProps } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { Context } from "../../.."
import XPageModal from "../../../components/topgun-ui-kit/xmodals/XPageModal"
import {Animator} from "../../../core/anims/Animator"
import { prevent } from "../../../core/utils/utils"

import styles from "../../../styles/Loyalty/LoyaltyStore.module.scss"
import styles2 from "../../../styles/Loyalty/LoyaltyStore.module.scss"

const items1: CollapseProps['items'] = [
  
]

const SupportModal: FC = () => {

  const { loyaltyStore } = useContext(Context)

  const [items, setItems] = useState([{
    id: "supp2",
    key: '1',
    label: 'Зачем нужна подписка?',
    children: <p>Подписка активирует Ваш счет, куда будет приходить кешбэк. Всего есть 2 тарифа подписки: БАЗА и ПЛЮС. У каждого тарифа, помимо активации счета и начислений кешбэка, есть дополнительные преимущества, которые вы можете изучить в соответствующем разделе.</p>,
  },
  {
    id: "supp3",
    key: '2',
    label: 'Где я могу тратить кешбэк?',
    children: <p>К вашему вниманию представлен магазин, в котором вы можете преобретать товары, оплачивая до 50% стоимости - кешбэком, а остаток - настоящими рублями.</p>,
  },
  {
    id: "supp4",
    key: '3',
    label: 'Могу ли я оплатить стрижку бонусами?',
    children: <p>Накопленный кешбэк можно тратить только в магазине <b>TOPGUN+</b>. Их невозможно потратить на частичную или полную оплату стрижки в барбершопах TOPGUN.</p>,
  },
  {
    id: "supp5",
    key: '4',
    label: 'Для кого эта программа?',
    children: <p>Поскольку, главным элементом лояльности является - кешбэк за оплаченные услуги в TOPGUN, клиентами программы могут быть только активные клиенты TOPGUN.</p>,
  },
  {
    id: "supp6",
    key: '5',
    label: 'Мобильное приложение',
    children: <p><a href="#" onClick={() => {
      loyaltyStore.setIsSupportVisible(false)
      loyaltyStore.setIsMAppVisible(true)
    }}>Инструкция</a></p>,
  },
  {
    id: "supp7",
    key: '6',
    label: 'Куда обратиться с вопросами?',
    children: <p>При возникновении любых проблем связанных с работой сайта, просим незамедлительно обращаться к нам <a href="https://t.me/topgun_plus_support" target="_blank">напрямую в Telegram</a></p>,
  }] as CollapseProps['items'])

  useEffect(() => {
    if (loyaltyStore.isSupportVisible) {
      const animator = new Animator({
        timeline: [
          {point: 500, id: "supp1", styles: {opacity: "1"}},
          {point: 900, id: "supp2", styles: {opacity: "1", left: "0px"}},
          {point: 1100, id: "supp3", styles: {opacity: "1", left: "0px"}},
          {point: 1300, id: "supp4", styles: {opacity: "1", left: "0px"}},
          {point: 1500, id: "supp5", styles: {opacity: "1", left: "0px"}},
          {point: 1700, id: "supp6", styles: {opacity: "1", left: "0px"}},
          {point: 1900, id: "supp7", styles: {opacity: "1", left: "0px"}},
          {point: 2200, id: "supp8", styles: {opacity: "1"}}
        ]
      })
  
      animator.run()
    } else {
      const animator = new Animator({
        timeline: [
          {point: 100, id: "supp1", styles: {opacity: "0"}},
          {point: 100, id: "supp2", styles: {opacity: "0", left: "-200px"}},
          {point: 100, id: "supp3", styles: {opacity: "0", left: "-200px"}},
          {point: 100, id: "supp4", styles: {opacity: "0", left: "-200px"}},
          {point: 100, id: "supp5", styles: {opacity: "0", left: "-200px"}},
          {point: 100, id: "supp6", styles: {opacity: "0", left: "-200px"}},
          {point: 100, id: "supp7", styles: {opacity: "0", left: "-200px"}},
          {point: 100, id: "supp8", styles: {opacity: "0"}}
        ]
      })
  
      animator.run()
    }
  }, [loyaltyStore.isSupportVisible])

  return (
    <>
      <XPageModal
        id="support"
        visible={loyaltyStore.isSupportVisible}
        onClose={() => {
          loyaltyStore.setIsSupportVisible(false)
        }}
        activeZone={false}
        title={<><b className="xmtitle">Служба поддержки <strong>клиентов</strong></b></>}
        content={<>
          <div className={styles.objectively}>
            <h4 id="supp1" style={{opacity: "0", transition: "all 0.3s ease"}}>Часто задаваемые вопросы</h4>

            <Collapse defaultActiveKey={[]} ghost items={items} />

            <p id="supp8" style={{
              textAlign: "center",
              marginTop: "32px",
              opacity: "0",
              transition: "all 0.3s ease"
            }}>
              <a href="#" onClick={(ev) => {
                prevent(ev)
                loyaltyStore.setIsSupportVisible(false)
                loyaltyStore.setIsRulesVisible(true)
              }}>Правила сервиса</a>
            </p>
          </div>
        </>} />
    </>
  )
}

export default observer(SupportModal)